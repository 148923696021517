<template>
    <div v-if="point !== null" class="container-information-point">
        <div @click="$root.$emit('back', 'PointsList')"
             style="position: absolute; top:-40px; left:18px; color:#337AB7;"><i class="icon back"></i>Назад
        </div>
        <div @click="back('PointsList')" class="close"><i class="icon close"></i></div>

        <h1 class="title-container" v-if="isPickupPoint">
            <span class="pick-uppoint">
                {{ point.carrierValue === 'FivePost' ? point.name : 'Пункт выдачи заказов ' + point.carrierValue }}
            </span>
        </h1>
        <h1 class="title-container" v-else-if="isShop">{{ point.name }}</h1>
        <h1 class="title-container" v-else>{{ point.carrierValue === 'FivePost' ? point.name : 'Постамат ' + point.carrierValue }}</h1>
        <div class="container-information-point-block">
            <div class="address">
                <p class="title"><i class="icon address"></i>Адрес</p>
                <p class="description">{{point.address}}</p>
            </div>
            <div class="delivery">
                <p class="title"><i class="icon delivery"></i>Доставка {{ point.transit }}, <span class="cost">{{ point.cost }}₽</span></p>
            </div>
            <div class="serviceDescription" style="margin-top:-15px;">
                <p style="color:#333333; font-size:13px; margin-bottom: 8px;" v-if="point.serviceDescriptionValue && point.serviceDescriptionValue.yes !== ''">
                    <i class="icon check"> </i>
                    {{point.serviceDescriptionValue.yes}}
                </p>
                <p style="color:#EB5757; font-size:13px;" v-if="point.serviceDescriptionValue && point.serviceDescriptionValue.no !== ''">
                  <i class="icon not_available"></i>
                  {{point.serviceDescriptionValue.no}}
                </p>

            </div>
            <div class="paymentLimitsDescription" style="font-size:13px;" v-if="point.payLimitsValue.texts.length > 0">
                <p v-for="(payLimit, i) in point.payLimitsValue.texts" :key="i" :style="payLimit.style">
                    <span v-html="payLimit.icon"></span> {{ payLimit.text }}
                </p>
            </div>
            <div class="howToRideDescription" style="font-size:13px;" v-if="point.description">
                <p class="title"><i class="icon route"></i>Как добраться?</p>
                <p class="description"> {{ point.description }}</p>
            </div>

            <div class="time-work">
                <p class="title"><i class="icon hours"></i>Время работы</p>
                <p class="description" v-html="showHours(point)"></p>
            </div>
<!--            <div class="whom-delivery">-->
<!--                <p class="title"><i class="icon delivery-man"></i>Компания доставщика</p>-->
<!--                <p class="description">{{ getCarrier(point)}}</p>-->
<!--            </div>-->
        </div>

        <div class="button-container" @click="selectPoint(point)">
            <div class="button green apply vertical-center">
                Выбрать
            </div>
        </div>

    </div>
</template>

<script>

    import {join, each, trim} from 'lodash'
    import {mapGetters} from 'vuex';
    import Filters from "../helpers/Filters";

    export default {
        name: 'container-information-point',
        props: ['point'],
        data() {
            return {}
        },
        computed: {
            isPickupPoint() {
                return this.point.point_type == Filters.POINT_TYPE_PICKUP_POINT;
            },
            isShop() {
                return this.point.point_type == Filters.POINT_TYPE_SHOP;
            },
        },
        beforeUpdate() {
            document.querySelector('.container-information-point-block').scrollTop = 0;
        },
        methods: {
            ...mapGetters(['getPrevState']),

            getCarrier(point) {
                 if (point.carrierValue === 'FivePost') {
                     return 'Пятёрочка'
                 }
                 return point.carrierValue;
            },

            showHours(point) {
                if (!point.schedule) {
                    return null;
                }

                var schedule = point.schedule;
                var days = ['Пн: ', 'Вт: ', 'Ср: ', 'Чт: ', 'Пт: ', 'Сб: ', 'Вс: '];
                var scheduled = schedule.split('/');
                each(scheduled, function (val, i) {
                    if (!days[i]) return;

                    if (!trim(scheduled[i])) scheduled[i] = 'закрыто';
                    scheduled[i] = days[i] + scheduled[i];
                    return scheduled;
                });
                return join(scheduled, '<br/>');
            },

            back() {
                this.$root.$emit('back');
                if (this.point.isMap) {
                    this.$parent.$parent.toogleToolbarMapList(true);
                } else {
                    this.$parent.$parent.toogleToolbarMapList(false);
                }
            },

            selectPoint(point) {
                var message = {
                    type: 'selectPoint',
                    data: point
                };
                window.parent.postMessage(JSON.stringify(message), '*');
            }
        },
    }
</script>
