<template>
    <div class="point" @click="showPoint(pointId)">
        <p class="title-address" style="font-size:17px">{{ point.address }}</p>
        <div class="additional-options" style="font-size: 13px;">
          <span style="color: #828282"> {{ getCarrier(point) }} • {{ point.cost }}₽ • Доставка: {{ point.transit }}
              <span v-if="point.payLimitsValue">
                  <span v-if="point.payLimitsValue.limited">•
                    <span v-for="(payLimit , i) in point.payLimitsValue.texts" :key="i">
                      <span> {{ payLimit.text }}</span>
                    </span>
                  </span>
              </span>
          </span>
            <p style="color:#828282"
               v-if="point.serviceDescriptionValue && point.serviceDescriptionValue.no !== ''">
                {{ point.serviceDescriptionValue.no }}</p>
        </div>
    </div>
</template>

<script>
import Points from '../helpers/Points'

export default {
    name: 'container-point',
    props: [
        'pointId'
    ],
    computed: {
        point() {
            return Points.getRestructurePoint(this.pointId);
        }
    },
    methods: {

        showPoint(pointId) {
            this.$root.$emit('get:point', pointId);
        },

        getCarrier(point) {
            if (point.carrierValue === 'FivePost') {
                return '5post';
            } else if (point.carrierValue === 'Store') {
                return  'Магазин';
            }

            return point.carrierValue;
        }
    }
}
</script>
