<template>
    <div class="container">
        <div class="toggle-map-list">
            <div class="toolbar-toogle vertical-center">
                <div class="map buttons-toogle-toolbar" @click="toogleToolbarMapList(true)"
                     :class="{'active': toogleToolbar === true && this.getCurrentState !== 'InformationPoint'}">Карта
                </div>
                <div class="list buttons-toogle-toolbar" @click="toogleToolbarMapList(false)"
                     :class="{'active': toogleToolbar === false || this.getCurrentState === 'InformationPoint' }">Список
                </div>
            </div>
        </div>
        <Map/>
        <ContainerRight></ContainerRight>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Utils from '../helpers/Utils';
import Map from './container-map';
import Points from '../helpers/Points';
import ContainerRight from './container-right';
import Filters from "../helpers/Filters";


export default {
    name: 'container-general',
    components: {Map, ContainerRight},
    data() {
        return {
            MapYandex: false,
            toogleToolbar: true,
            loading: true,
        }
    },
    computed: mapGetters(['getVisiblePointIds', 'getCurrentState', 'getActiveFilters', 'getFilters']),
    methods: {
        toogleToolbarMapList(val) {
            this.toogleToolbar = val
            return this.toogleToolbar;
        }
    },
    mounted() {
        let filterSettings = JSON.parse(JSON.stringify(Filters.FILTER_SETTINGS));

        filterSettings.disableFilters = Utils.getUrlParameter('disableFilters') !== 'true';

        let disableStores = true;
        let disablePickUp = false;
        let disableCompany = false;

        if (Utils.getUrlParameter('disablePickUp') === 'true') {
            disablePickUp = true;
        } else if (Utils.getUrlParameter('disablePickUp') === 'false') {
            disablePickUp = false;
        }

        if (Utils.getUrlParameter('disableStores') === 'true') {
            disableStores = true;
        } else if (Utils.getUrlParameter('disableStores') === 'false') {
            disableStores = false;
        }

        if (Utils.getUrlParameter('disableCompanyFilter') === 'true') {
            disableCompany = true;
        } else if (Utils.getUrlParameter('disableCompanyFilter') === 'false') {
            disableCompany = false;
        }

        if (disablePickUp) {
            filterSettings.types.POSTAMATS = false;
            filterSettings.types.PICKUP_POINTS = false;

            filterSettings.carriers.Boxberry = false;
            filterSettings.carriers.CDEK = false;
            filterSettings.carriers.FivePost = false;
            filterSettings.carriers.Hermes = false;
            filterSettings.carriers.PickPoint = false;
            filterSettings.carriers.Ozon = false;
        } else {
            filterSettings.types.POSTAMATS = true;
            filterSettings.types.PICKUP_POINTS = true;
        }

        if (disableStores) {
            filterSettings.types.STORES = false;
            filterSettings.carriers.Store = false;
        } else {
            filterSettings.types.STORES = true;
        }

        if (disablePickUp && disableStores) {
            filterSettings.services.TRY_ON = false;
            filterSettings.services.PARTIAL_RETURN = false;
            filterSettings.services.TRY_ON_SHOES_AND_OUTERWEAR = false;
        } else {
            filterSettings.services.TRY_ON = Utils.getUrlParameter(
                'disableDressingRoomFilter'
            ) !== 'true';
            filterSettings.services.PARTIAL_RETURN = Utils.getUrlParameter(
                'disablePartialReturnFilter'
            ) !== 'true';
            filterSettings.services.TRY_ON_SHOES_AND_OUTERWEAR = Utils.getUrlParameter(
                'disablePartialReturnFilter'
            ) !== 'true';
        }

        if(disableCompany) {
            filterSettings.carriers.Boxberry = false;
            filterSettings.carriers.CDEK = false;
            filterSettings.carriers.FivePost = false;
            filterSettings.carriers.Hermes = false;
            filterSettings.carriers.PickPoint = false;
            filterSettings.carriers.Ozon = false;
            filterSettings.carriers.Store = false;
        } else {
            filterSettings.carriers.Boxberry = Utils.getUrlParameter('disableCompanyBoxberry') !== 'true';
            filterSettings.carriers.CDEK = Utils.getUrlParameter('disableCompanyCdek') !== 'true';
            filterSettings.carriers.FivePost = Utils.getUrlParameter('disableCompanyFivePost') !== 'true';
            filterSettings.carriers.Hermes = Utils.getUrlParameter('disableCompanyHermes') !== 'true';
            filterSettings.carriers.PickPoint = Utils.getUrlParameter('disableCompanyPickpoint') !== 'true';
            filterSettings.carriers.Ozon = Utils.getUrlParameter('disableCompanyOzon') !== 'true';
            filterSettings.carriers.Store = Utils.getUrlParameter('disableCompanyStore') !== 'true';
        }

        this.$store.commit('setFilterSettings', filterSettings);
        Points.requestPoints(filterSettings).then(() => {
            this.$store.commit('setPointsLoaded', true)
        })
        .catch(error => alert(error));
    }
}
</script>
