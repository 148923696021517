<template>
    <div class="container-map col-8"
         :class="{'block' : this.$parent.toogleToolbar, 'none': !this.$parent.toogleToolbar}">
        <div class="loader" style="opacity: 50%; z-index: 10; top:0; left:0; position: fixed"
             v-show="getMapLoader">
            <half-circle-spinner
                :animation-duration="1000"
                :size="60"
                color="#337ab7"
            />
        </div>
        <div id="map"></div>
        <div class="settings-map">
            <div class="filter-search-toolbar">
                <div class="filter button" v-if="getFilterSettings.disableFilters"
                     @click="openFilter()"><i class="icon filter"></i>Фильтр</div>
                <div class="search button" @click="openSearch()"><i class="icon search"></i>Поиск</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import MapHelpers from '../helpers/Map';
import {HalfCircleSpinner} from 'epic-spinners';
import {debounce} from 'lodash';
import Points from '../helpers/Points';
import Filters from "../helpers/Filters";

export default {
    name: 'container-map',
    components: {HalfCircleSpinner},
    data() {
        return {}
    },
    computed: {
        ...mapGetters(['getPointsLoaded', 'getActiveFilters', 'getMapLoader', 'getFilterSettings'])
    },
    watch: {
        getPointsLoaded(val) {
            if (!val) {
                return;
            }

            let checkInitMap = (callback) => {
                setTimeout(() => {
                    if (this.ymapsIsLoaded) {
                        callback();
                    } else {
                        checkInitMap(callback);
                    }
                }, 50);
            }
            checkInitMap(() => {
                let Map = this.MapInstance;

                Map.setPoints(Points.pointsResult);
                this.setMapLoader(false);

                Map.mapObjectsArray = Map.DataForMap;
                this.updateVisiblePointIds();


                this.ObjectIEventManager = Map.MapYandex.events.add(
                    ['boundschange', 'sizechange'],
                    debounce(this.updateVisiblePointIds, 500)
                );


                // Анимация приближения при клике на точку
                Map.addEvent(
                    Map.ObjectManager.objects,
                    'click',
                    (e) => {
                        this.$parent.toogleToolbarMapList(false);
                        var item = e.originalEvent.currentTarget._objectsById[e.get('objectId')]
                        this.$root.$emit('get:point', item.properties.itemId, true)
                    }
                );
            })
        },

        getActiveFilters(filterState) {
            if (!filterState) {
                return;
            }

            this.setMapLoader(true);
            this.setRightPanelLoader(true);

            // use setTimeout for remove freeze after filter apply
            setTimeout(() => {

                MapHelpers.filterState = JSON.parse(JSON.stringify(filterState));
                //Variable for filter string
                let query = '';

                let pointTypesQuery = [];
                //Take checked filters
                for (const pointType in MapHelpers.filterState.types) {
                    if(MapHelpers.filterState.types[pointType]) {
                        //take id of checked filter by name
                        const typeId = Object.keys(Filters.TYPES_NAMES).find(
                            key => Filters.TYPES_NAMES[key] === pointType
                        );
                        //take query string if exist and concat it or just make new query string
                        pointTypesQuery.push('properties.pointType === "' + typeId + '"');
                    }
                }

                let pointServicesQuery = [];
                if (MapHelpers.filterState.services.TRY_ON) {
                    pointServicesQuery.push('properties.tryOnAll === 1');
                }

                if (MapHelpers.filterState.services.TRY_ON_SHOES_AND_OUTERWEAR) {
                    pointServicesQuery.push('properties.tryOnPartial ===  1');
                }

                if (MapHelpers.filterState.services.PARTIAL_RETURN) {
                    pointServicesQuery.push('properties.partialReturn === 1');
                }

                let pointCarriersQuery = [];
                for (const carrier in MapHelpers.filterState.carriers) {
                    if(MapHelpers.filterState.carriers[carrier]) {
                        const carrierId = Object.keys(Points.pointsResult.carriersMap).find(
                            key => Points.pointsResult.carriersMap[key] === carrier
                        );
                        pointCarriersQuery.push('properties.carrier === ' + carrierId);
                    }
                }

                const pointTypesQueryString = pointTypesQuery.join(' || ');
                const pointServicesString = pointServicesQuery.join(' && ');
                const pointCarriersString =pointCarriersQuery.join(' || ');

                if(pointTypesQueryString) {
                    query += `(${pointTypesQueryString}) && `;
                }

                if(pointServicesString) {
                    query += `(${pointServicesString}) && `;
                }

                if(pointCarriersString) {
                    query += `(${pointCarriersString}) && `;
                }

                this.Map.getInstance().ObjectManager.setFilter(query.slice(0, -4));

                this.updateVisiblePointIds();
                this.setMapLoader(false);
                this.setRightPanelLoader(false);

            }, 1);

        },
    },
    created() {
        this.Map = MapHelpers;
        this.ObjectManager = null;
        this.ObjectIEventManager = null;
        this.MapInstance = false;
        this.ymapsIsLoaded = false;
    },
    mounted() {
        //fix for templateFactory In Ymaps
        window.getPointById = (pointId) => {
            this.$root.$emit('get:point', pointId, true)
        }

        let checkYmaps = (callback) => {
            setTimeout(() => {
                if (window.ymaps.geolocation) {
                    callback(window.ymaps);
                } else {
                    checkYmaps(callback);
                }
            }, 50);
        }
        checkYmaps((ymaps) => {
            this.Map.getInstance(ymaps).init().then((Map) => {
                this.MapInstance = Map;
                this.ymapsIsLoaded = true;
                this.setIsMapInit(true);
            }).catch((msg) => {
                    console.log(msg);
                    this.$parent.loading = false;
                    this.setMapLoader(false);
                }
            );
        })
    },
    methods: {

        ...mapMutations(['setRightPanelState', 'setMapLoader', 'setRightPanelLoader', 'setVisiblePointIds', 'setIsMapInit']),

        ...mapGetters(['getVisiblePointIds']),

        openFilter() {
            this.$parent.toogleToolbarMapList(false);
            this.setRightPanelState('FilterPage')
        },

        openSearch() {
            this.$parent.toogleToolbarMapList(false);
            this.setRightPanelState('PointsList')
        },

        updateVisiblePointIds: function () {
            // После каждого сдвига карты будем смотреть, какие объекты попадают в видимую область.
            let visiblePointIds = this.Map.getInstance().getVisiblePointIds(
                this.Map.getInstance().mapObjectsArray,
                this.Map.getInstance().getMyMap()
            );

            this.setVisiblePointIds(visiblePointIds)
        }
    },
}
</script>
