import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        isMapInit: false,
        mapLoader: true,
        rightPanelLoader: false,
        pointsLoaded: false,
        visiblePointIds: [],
        prevState: 'PointList',
        state: 'PointsList',
        allState: ['InformationPoint', 'FilterPage', 'PointsList'],
        isFilterActive: false,
        filters: {},
        filterSettings: {},
        activeFilters: [],
        tooltipTypes: {
            PARTIAL_RETURN: false,
            TRY_ON: false,
            TRY_ON_SHOES_AND_OUTERWEAR: false
        }
    },
    mutations: {
        setVisiblePointIds(state, visiblePointIds) {
            state.visiblePointIds = visiblePointIds;
        },

        setRightPanelState(state, newState) {
            if (state.allState.indexOf(newState) > -1) {
                state.prevState = state.state;
                state.state = newState
            } else {
                state.state = 'PointsList'
            }
        },

        setFilters(state, filters) {
            state.filters = filters;
        },

        setFilterSettings(state, filterSettings) {
            state.filterSettings = filterSettings;
        },

        setActiveFilters(state, activeFilters) {
            state.activeFilters = activeFilters;
        },

        setActiveTooltipState(state, filterBtnTooltipType) {
            for (const tooltipType in state.tooltipTypes) {
              if (tooltipType !== filterBtnTooltipType) {
                state.tooltipTypes[tooltipType] = false;
              }
            }

            state.tooltipTypes[filterBtnTooltipType] = !state.tooltipTypes[filterBtnTooltipType];
        },

        setPointsLoaded(state, pointsLoaded) {
            state.pointsLoaded = !!pointsLoaded;
        },

        setMapLoader(state, mapLoader) {
            state.mapLoader = !!mapLoader;
        },

        setRightPanelLoader(state, rightPanelLoader) {
            state.rightPanelLoader = !!rightPanelLoader;
        },
        setIsMapInit(state, isMapInit) {
            state.isMapInit = isMapInit;
        },
    },
    getters: {

        getIsFilterActive(state) {
            return state.isFilterActive;
        },

        getCurrentState(state) {
            return state.state;
        },

        getAllStates(state) {
            return state.allState;
        },

        getPrevState(state) {
            return state.prevState;
        },

        getActiveFilters(state) {
            if (state.activeFilters.length === 0) {
                return {
                    carriers: {},
                    types: {},
                    services: {}
                };
            }
            return state.activeFilters;
        },

        getStateTooltipTypes(state) {
            return state.tooltipTypes;
        },

        getFilters(state) {
            return state.filters;
        },

        getFilterSettings(state) {
            return state.filterSettings;
        },

        getVisiblePointIds(state) {
            return state.visiblePointIds;
        },

        getPointsLoaded(state) {
            return state.pointsLoaded;
        },

        getMapLoader(state) {
            return state.mapLoader;
        },

        getRightPanelLoader(state) {
            return state.rightPanelLoader;
        },

        getIsMapInit(state) {
            return state.isMapInit;
        },
    },
})
